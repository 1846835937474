import { Component, Input, OnChanges, SimpleChanges, ViewChildren, QueryList } from '@angular/core';
import { ITactic } from '../../models/solution';
import { ETacticColumnNodeState } from '../tactic-column-node/tactic-column-node-state';
import { ITacticColumn } from './tactic-column';
import { TacticColumnNodeComponent } from '../tactic-column-node/tactic-column-node.component';

@Component({
  selector: 'mitre-tactic-column',
  templateUrl: './tactic-column.component.html',
  styleUrls: [ './tactic-column.component.scss' ],
})
export class TacticColumnComponent implements ITacticColumn, OnChanges {
  /**
   * Tactic data to populate the column
   */
  @Input() tactic: ITactic;

  /**
   * Determines if this is the last displayed column
   */
  @Input() firstColumn: boolean;

  /**
   * Determines if this is the last displayed column
   */
  @Input() lastColumn: boolean;

  /**
   * Determines if ui elements are aligned for the right half of the screen
   */
  @Input() rightSidedColumn: boolean;

  /**
   * Count of the top-level techniques with product coverage
   */
  coveredCount: number;

  /**
   * Count of the top-level techniques without product coverage
   */
  uncoveredCount: number;

  /**
   * Total count of all techniques
   */
  totalCount: number;

  /**
   * Reference to top-level node components
   */
  @ViewChildren(TacticColumnNodeComponent)
  nodes: QueryList<TacticColumnNodeComponent>;

  constructor() {
    // set initial values
    this.coveredCount = 0;
    this.uncoveredCount = 0;
    this.totalCount = 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // calculate the totals based on the new tactic techniques
    if (changes.tactic && changes.tactic.currentValue !== changes.tactic.previousValue) {
      this.coveredCount = this.tactic.techniques.filter(t => t.products.length > 0).length;
      this.uncoveredCount = this.tactic.techniques.filter(t => t.products.length === 0).length;
      this.totalCount = this.coveredCount + this.uncoveredCount;
    }
  }

  /**
   * Checks all nodes with the same code as the passed value and updates the state
   * @param code Technique code to identify
   * @param state Node state to change to
   */
  changeNodeState(code: string, state: ETacticColumnNodeState) {
    const matchingNodes = this.nodes.filter(n => n.technique.code === code);

    for (const node of matchingNodes) {
      node.state = state;
    }
  }

  /**
   * Cycles through all nodes in the column looking for sub-techniques - then sets the showSubTechniques flag
   * @param show New state of the showSubTechniques flag
   */
  toggleSubTechniques(show: boolean): void {
    for (const node of this.nodes.toArray()) {
      if (node.technique.subTechniques.length) {
        node.showSubTechniques = show;
      }
    }
  }
}
