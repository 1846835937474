import {
  AfterContentInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { selectSelectedSolutionReference } from '../store/selectors/solution.selector';

@Directive({
  selector: '[mitreReadMoreLess]',
})
export class ReadMoreLessDirective implements AfterContentInit, OnChanges, OnDestroy {

  @Input() mitreReadMoreLess: string;

  private readonly _readMore = 'Read more';
  private readonly _readLess = 'Read less';
  private readonly _className = 'read-more-less';

  private _length = 120;
  private _originalText = '';
  private _truncatedTextParts: string[] = [];
  private _truncated$: Subject<boolean> = new Subject();
  private _contentReady = false;
  private _htmlRegex: RegExp = /<[^>]*>/gi;

  /**
   * Store ref to current solution in local storage
   */
  private _solutionLocalStorageRef = new BehaviorSubject<string>(null);

  /**
   * Subject for garbage collection
   */
  private _destroy$ = new Subject<void>();

  constructor(private _elRef: ElementRef, private _renderer: Renderer2, private readonly _store: Store) {
    this._store.pipe(
      select(selectSelectedSolutionReference),
      filter(solutionRef => Boolean(solutionRef)),
      takeUntil(this._destroy$)
    ).subscribe(solutionRef => this._solutionLocalStorageRef.next(`cyberes-mitre-attack-${solutionRef}`));
  }

  // Remove html from text string using regex
  private _textWithoutHtml(text: string): string {
    return text.replace(this._htmlRegex, '');
  }

  private _checkIfTextShouldBeTruncated(text: string): boolean {
    return this._textWithoutHtml(text).length < this._length;
  }

  // add text string in at a certain index
  private _splice(subject: string, insertString: string, index: number): string {
    return `${subject.slice(0, index)}${insertString}${subject.slice(index, subject.length)}`;
  }

  private _splitTextIntoParts(text: string): string[] {
    if (this._checkIfTextShouldBeTruncated(text)) {
      return [ text ];
    }

    const strippedText = this._textWithoutHtml(text);
    let htmlCharOffset = 0;
    let start = strippedText.slice(0, this._length);
    let end = strippedText.slice(this._length, text.length);
    const htmlParts = text.match(this._htmlRegex);

    if (htmlParts) {
      for (const [i, htmlPart] of htmlParts.entries()) {
        const index = text.search(htmlPart) - htmlCharOffset;

        if (index < this._length) {
          start = this._splice(start, htmlPart, index + htmlCharOffset);
        } else {
          end = this._splice(end, htmlPart, index + htmlCharOffset);
        }

        htmlCharOffset += htmlPart.length;
      }
    }

    return [ start, end ];
  }

  private _newTextStringFromParts(shouldBeTruncated: boolean): string {
    return shouldBeTruncated ? this._truncatedTextParts[0] + '&hellip;' : this._truncatedTextParts.join('');
  }

  private _handleTruncationChange(willtruncate: boolean) {
    const link = document.createElement('span');
    link.className = this._className;
    link.addEventListener('click', () => willtruncate ? this.showAll() : this.truncate());

    const text  = willtruncate ? this._readMore : this._readLess;

    this._renderer.appendChild(link, this._renderer.createText(text));
    const nextText  = this._newTextStringFromParts(willtruncate);
    this._elRef.nativeElement.innerHTML = `${nextText} `;

    if (this._truncatedTextParts.length > 1) {
      this._renderer.appendChild(this._elRef.nativeElement, link);
    }
  }

  setup(): void {
    this._elRef.nativeElement.innerText = '';
    this._truncated$.subscribe((willtruncate) => this._handleTruncationChange(willtruncate));
    this._originalText = this._elRef.nativeElement.innerText || this.mitreReadMoreLess;
    this._truncatedTextParts = this._splitTextIntoParts(this._originalText);
    const showTruncation = this._truncatedTextParts.length > 1 && localStorage.getItem(this._solutionLocalStorageRef.value) === '1';
    this._truncated$.next(showTruncation);

    if (localStorage.getItem(this._solutionLocalStorageRef.value) !== '1') {
      localStorage.setItem(this._solutionLocalStorageRef.value, '1');
    }
  }

  ngOnChanges(): void {
    if (this._contentReady) {
      this.reset();
      this.setup();
    }
  }

  ngAfterContentInit() {
    this.reset();
    this.setup();
    this._contentReady = true;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  truncate(): void {
    this._truncated$.next(true);
  }

  showAll(): void {
    this._truncated$.next(false);
  }

  reset(): void {
    this._originalText = '';
    this._truncatedTextParts = [];
  }
}
