import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SolutionComponent } from './shared/components/solution/solution.component';
import { DEFAULT_SOLUTION_REFERENCE } from './shared/constants/solution.constants';
import { SolutionGuard } from './shared/guards/solution.guard';
import { SolutionResolver } from './shared/resolvers/solution.resolver';

const routes: Routes = [
  { path: '', redirectTo: `/${DEFAULT_SOLUTION_REFERENCE}`, pathMatch: 'full' },
  {
    path: ':ref',
    canActivate: [SolutionGuard],
    resolve: { SolutionResolver },
    component: SolutionComponent,
  },
  { path: '**', redirectTo: `/${DEFAULT_SOLUTION_REFERENCE}` },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
