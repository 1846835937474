import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SOLUTION_REFERENCES } from '../constants/solution.constants';
import { ISolutionColorScheme, ITechnique } from '../models/solution';
import { IAppState } from '../store';
import { selectSelectedSolutionColorScheme, selectSelectedSolutionReference, selectSolutionColorSchemeByReference } from '../store/selectors/solution.selector';

@Injectable({
  providedIn: 'root'
})
export class TechniqueService {
  /**
   * Observble to check if current section is layered analytics
   */
  isLayeredAnalytics$: Observable<boolean> = this._store.pipe(
    select(selectSelectedSolutionReference),
    switchMap(ref => of(ref === SOLUTION_REFERENCES[0])),
  );

  /**
   * The current solution color scheme - defaults to the first section color scheme when layered analytics is called
   */
  solutionColorScheme$ = combineLatest([
    this._store.pipe(select(selectSelectedSolutionColorScheme)),
    this._store.pipe(select(selectSolutionColorSchemeByReference, { reference: SOLUTION_REFERENCES[1] })),
    this.isLayeredAnalytics$,
  ]).pipe(
    switchMap(([solutionColorScheme, isLayeredAnalyticsColorScheme, isLayeredAnalytics]: [ISolutionColorScheme, ISolutionColorScheme, boolean]) => of(isLayeredAnalytics ? isLayeredAnalyticsColorScheme : solutionColorScheme))
  );

  constructor(private _store: Store<IAppState>) { }

  /**
   * Get all solutions covered in a technique in the correct order
   */
  getProductSolutions(technique: ITechnique): string[] {
    // get the current technique product solutions
    let allSolutionRefs = technique.products.filter(p => p.solution).map(p => p.solution);

    // add the sub-technique product solutions
    if (technique.subTechniques && technique.subTechniques.length) {
      for (const subTechnique of technique.subTechniques) {
        allSolutionRefs = allSolutionRefs.concat(subTechnique.products.filter(p => p.solution).map(p => p.solution));
      }
    }

    // filter ordered list on all solution refs found
    return SOLUTION_REFERENCES.filter(ref => allSolutionRefs.indexOf(ref) > -1);
  }
}
