import { A11yModule } from '@angular/cdk/a11y';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  AccordionModule, ColorServiceModule,
  colorSets,
  IconModule,
  TabsetModule
} from '@ux-aspects/ux-aspects';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ModalContentComponent } from './shared/components/modal-content/modal-content.component';
import { ModalComponent } from './shared/components/modal/modal.component';
import { SolutionComponent } from './shared/components/solution/solution.component';
import { TechniqueModalContentComponent } from './shared/components/technique-modal-content/technique-modal-content.component';
import { TechniqueProductListComponent } from './shared/components/technique-product-list/technique-product-list.component';
import { SolutionGuard } from './shared/guards/solution.guard';
import { SolutionResolver } from './shared/resolvers/solution.resolver';
import { SharedModule } from './shared/shared.module';
import { SolutionEffects } from './shared/store/effects/solution.effect';
import { appReducers } from './shared/store/reducers/index';
@NgModule({
  declarations: [AppComponent, ModalContentComponent, ModalComponent, TechniqueModalContentComponent, TechniqueProductListComponent,],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    A11yModule,
    ModalModule.forRoot(),
    IconModule,
    TabsetModule,
    AccordionModule,
    TabsetModule,
    ColorServiceModule.forRoot(colorSets.microFocus),
    SharedModule,
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot([SolutionEffects])
  ],
  bootstrap: [AppComponent],
  entryComponents: [SolutionComponent, ModalContentComponent, TechniqueModalContentComponent],
  providers: [SolutionGuard, SolutionResolver],
})
export class AppModule {}
