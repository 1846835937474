import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ITechnique } from '../models/solution';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private _selectedSubtechnique: BehaviorSubject<ITechnique | null> = new BehaviorSubject(null);
  public selectedSubtechnique$ = this._selectedSubtechnique.asObservable();

  constructor() { }

  public setSelectedSubTechnique(subtechnique: ITechnique) {
    this._selectedSubtechnique.next(subtechnique);
  }

  public reset() {
    this._selectedSubtechnique.next(null);
  }
}
