import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { of, Subject, Observable } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { DEFAULT_SOLUTION_REFERENCE } from '../../constants/solution.constants';
import { ISolutionColorScheme, ITechnique } from '../../models/solution';
import { ModalService } from '../../services/modal.service';
import { SolutionGridService } from '../../services/solution-grid.service';
import { TechniqueService } from '../../services/technique.service';
import { IAppState } from '../../store';
import { EModalName, ShowModal } from '../../store/actions/modal.action';
import { selectSelectedSolution, selectSelectedSolutionReference, selectSolutionMainColorByReferences } from '../../store/selectors/solution.selector';
import { ITacticColumnNode } from './tactic-column-node';
import { TacticColumnNodeButtonComponent } from './tactic-column-node-button.component';
import { ETacticColumnNodeState } from './tactic-column-node-state';
import { ETacticColumnNodeStatus } from './tactic-column-node-status';

@Component({
  selector: 'mitre-tactic-column-node',
  templateUrl: './tactic-column-node.component.html',
  styleUrls: ['./tactic-column-node.component.scss'],
})
export class TacticColumnNodeComponent implements ITacticColumnNode, OnChanges, OnDestroy {
  /**
   * Subject for garbage collection
   */
  private _destroy$ = new Subject<void>();

  /**
   * Reference to the state enum for accessing in the view
   */
  ETacticColumnNodeState = ETacticColumnNodeState;

  /**
   * Reference to status enum for accessing in the view
   */
  ETacticColumnNodeStatus = ETacticColumnNodeStatus;

  /**
   * The technique
   */
  @Input() technique: ITechnique;

  /**
   * Pass in the currentTactic short name
   */
  @Input() currentTactic: string;

  /**
   * Determines if ui elements are aligned for the right half of the screen
   */
  @Input() rightSidedNode: boolean;

  /**
   * Determines if this is the last displayed column
   */
  @Input() lastNode: boolean;

  /**
   * Determine whether in covered or uncovered mode
   */
  status: ETacticColumnNodeStatus;

  /**
   * Determine the visual state of the node
   */
  @Input() state: ETacticColumnNodeState;

  /**
   * The current solution color scheme
   */
  colorScheme: ISolutionColorScheme;

  /**
   * Show sub-techniques in the UI (used for toggle button)
   */
  showSubTechniques = false;

  /**
   * Current solution reference
   */
  solutionReference$: Observable<string> = this._store.pipe(select(selectSelectedSolutionReference));

  /**
   * Get the bar colors based on the solution
   */
  barColors$: Observable<string[]> = this._store.pipe(
    select(selectSelectedSolution),
    switchMap(solution => {
      if (solution.reference === DEFAULT_SOLUTION_REFERENCE) {
        const references = this._techniqueService.getProductSolutions(this.technique);
        return this._store.pipe(select(selectSolutionMainColorByReferences, { references }));
      }

      return of([solution.colors.main]);
    }),
    takeUntil(this._destroy$)
  );

  /**
   * View child reference to node button component
  */
  @ViewChild(TacticColumnNodeButtonComponent)
  nodeButton: TacticColumnNodeButtonComponent;

  constructor(
    private _store: Store<IAppState>,
    private _techniqueService: TechniqueService,
    private _modalService: ModalService,
    private _solutionGridService: SolutionGridService
  ) {
    this.status = this.determineStatus();
    this.state = ETacticColumnNodeState.Active;
    this.showSubTechniques = this._solutionGridService.showSubTechniques$.value;

    // get the correct color scheme
    this._techniqueService.solutionColorScheme$
      .pipe(takeUntil(this._destroy$))
      .subscribe(colorScheme => this.colorScheme = colorScheme);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.technique && changes.technique.currentValue !== changes.technique.previousValue) {
      // set the status based on the technique having products
      this.status = this.determineStatus();

      // set state to active
      this.state = ETacticColumnNodeState.Active;
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
   * Toggles the visibility of sub-techniques
   */
  toggleSubTechnques(show: boolean) {
    this.showSubTechniques = show;
  }

  /**
   * Determines the current status of a technique based on the data
   */
  private determineStatus(): ETacticColumnNodeStatus {
    if (this.technique && this.technique.isOtherContent) {
      return ETacticColumnNodeStatus.OtherContent;
    }

    if ( this.technique && this.technique.hasProducts) {
      return ETacticColumnNodeStatus.Covered;
    }

    return ETacticColumnNodeStatus.NotCovered;
  }

  showTechniqueModal() {
    this._store.dispatch(new ShowModal({
      name: EModalName.Technique,
      technique: this.technique,
      currentTactic: this.currentTactic
    }));
  }

  showSubtechnique(subtechnique?: ITechnique) {
    this._modalService.setSelectedSubTechnique(subtechnique);
    this.showTechniqueModal();
  }

  toggleNodeButtonEnter(): void {
    this.nodeButton.mouseEnter();
  }

  toggleNodeButtonLeave(): void {
    this.nodeButton.mouseLeave();
  }
}
