import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TechniqueService } from '../../services/technique.service';
import { selectSelectedSolutionReference } from '../../store/selectors/solution.selector';

@Component({
  selector: 'mitre-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss'],
})
export class LegendComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  @Input() colors: string[];

  isSentinel = false;

  isLayeredAnalytics$: Observable<
    boolean
  > = this._techniqueService.isLayeredAnalytics$.pipe(
    takeUntil(this._destroy$)
  );

  constructor(
    private _techniqueService: TechniqueService,
    private readonly _store: Store
  ) {}

  ngOnInit(): void {
    this._store
      .select(selectSelectedSolutionReference)
      .pipe(
        filter((ref) => Boolean(ref)),
        takeUntil(this._destroy$)
      )
      .subscribe((ref) => (this.isSentinel = ref === 'sentinel'));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
