<div class="solution-grid" [ngStyle]="{ 'margin-top.px': gridMarginTop, 'width.px': gridWidth }">
  <ng-container *ngFor="let tactic of solution.tactics; index as tacticIndex">
    <mitre-tactic-column
      [tactic]="tactic"
      [rightSidedColumn]="tacticIndex >= solution.tactics.length / 2"
      [firstColumn]="tacticIndex === 0"
      [lastColumn]="tacticIndex === solution.tactics.length - 1"
      class="tactic-column"
    >
    </mitre-tactic-column>
  </ng-container>
</div>
