<div class="column-node__container" *ngIf="colorScheme">

  <!-- show sub techniques expand button or color bars -->
  <ng-container *ngIf="technique.subTechniques.length > 0; then subTechniques; else colorBars"></ng-container>

  <!-- node column button -->
  <mitre-tactic-column-node-button #nodeButton
    [technique]="technique"
    [currentTactic]="currentTactic"
    [status]="status"
    [state]="state"
    [rightSidedNode]="rightSidedNode"
    [lastNode]="lastNode"
    [hasSubTechniques]="technique.subTechniques.length > 0"
    [colorScheme]="colorScheme"
    (click)="showTechniqueModal()"
  ></mitre-tactic-column-node-button>

</div>

<!-- show the subtechniques -->
<div *ngIf="showSubTechniques"
[ngClass]="{
  'sub-techniques__expand-container': true,
  'sub-techniques__expand-container--not-covered': status === ETacticColumnNodeStatus.NotCovered
}">
  <div *ngFor="let subtechnique of technique.subTechniques; index as subTechniqueIndex">
    <mitre-tactic-column-sub-node
      [technique]="subtechnique"
      [parentTechnique]="technique"
      [firstNode]="subTechniqueIndex === 0"
      [rightSidedNode]="rightSidedNode"
      (showSubtechnique)="showSubtechnique(subtechnique)"
    >
    </mitre-tactic-column-sub-node>
  </div>
</div>

<!-- sub techniques expansion -->
<ng-template #subTechniques>
  <mitre-tactic-column-sub-node-toggle
    [ngClass]="{
      'sub-techniques__expand-btn--not-covered': status === ETacticColumnNodeStatus.NotCovered
    }"
    (toggleShow)="toggleSubTechnques($event)"
    [(show)]="showSubTechniques"
    [hoverColorChangeEnabled]="status === ETacticColumnNodeStatus.Covered"
    [count]="technique.subTechniques.length"
    (mouseEnter)="toggleNodeButtonEnter($event)"
    (mouseLeave)="toggleNodeButtonLeave($event)"
  ></mitre-tactic-column-sub-node-toggle>
</ng-template>

<!-- color bar template -->
<ng-template #colorBars>
  <mitre-color-bars
    *ngIf="(barColors$ | async) && status !== ETacticColumnNodeStatus.NotCovered"
    [colors]="(barColors$ | async)"
    height="59px"
  ></mitre-color-bars>
</ng-template>
