import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ISolutionData } from '../../models/data';
import { IAppData } from '../../models/data/app-data';
import {
  ISolution,
  ISolutionProduct,
  ITactic,
  ITechnique,
} from '../../models/solution';
import { SolutionService } from '../../services/solution.service';
import { IGetSolutionPayload } from '../actions/get-solution-payload';
import {
  ESolutionActions,
  GetAppData,
  GetAppDataFailed,
  GetAppDataSuccess,
  GetSolution,
  GetSolutionSuccess,
} from '../actions/solution.action';
import { selectAppData } from '../selectors/solution.selector';
import { IAppState } from '../state';
import { ESolutionProductCost } from '../../models/solution/solution-product-cost';
import { getEnumKeyByValue } from '../../utils/data.utils';

@Injectable()
export class SolutionEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store<IAppState>,
    private _solutionService: SolutionService
  ) {}

  /**
   * Finds the correct solution by reference. showAll flag toggles between all techniques
   * and techniques with products only.
   */
  @Effect()
  getSolution$ = this._actions$.pipe(
    ofType<GetSolution>(ESolutionActions.GetSolution),
    map((action) => action.payload),
    withLatestFrom(this._store.pipe(select(selectAppData))),
    switchMap(([payload, appData]: [IGetSolutionPayload, IAppData]) => {
      // take solution and mitre data and convert to final solution
      return of(new GetSolutionSuccess(convertToSolution(payload, appData)));
    })
  );

  /**
   * Gets the app data once from the json file, then pulls from the store every other time
   */
  @Effect()
  getAppData$ = this._actions$.pipe(
    ofType<GetAppData>(ESolutionActions.GetAppData),
    withLatestFrom(this._store.pipe(select(selectAppData))),
    map(([action, appData]: [any, IAppData]) => appData),
    switchMap((appData: IAppData) => {
      // use the cached data
      if (appData) {
        return of(new GetAppDataSuccess(appData));
      }

      // pull from the json file when not loaded
      return this._solutionService.getAppData().pipe(
        map((newAppData: IAppData) => new GetAppDataSuccess(newAppData)),
        catchError((err) => of(new GetAppDataFailed()))
      );
    })
  );
}

/**
 * Converts the app data into a Solution object
 * @param payload payload for the requested action, including the requested solution
 * @param appData appData to compile into a solution
 */
function convertToSolution(
  payload: IGetSolutionPayload,
  appData: IAppData
): ISolution {
  const solutionData: ISolutionData = appData.solutionData.find(
    (s) => s.reference === payload.reference
  );

  // map tactics with the correct data
  const tactics: ITactic[] = appData.mitreData.tactics.map((tactic) => {
    const techniques: ITechnique[] = appData.mitreData.techniques.filter(
      (technique) => tactic.techniques.indexOf(technique.id) > -1
    );

    // map the selected mitre attack techniques combined with the solution products
    let techniquesWithProducts: ITechnique[] = techniques.map((technique) => {
      // get the products links from the solution data by the current tactic
      const techniqueProducts = solutionData.techniqueProducts.find(
        (techniqueProduct) => techniqueProduct.code === technique.code
      )?.products;

      const products: ISolutionProduct[] =
        techniqueProducts
          ?.filter((tp) => tp.tactic === tactic.shortName)
          .map((product) => ({
            ...product,
            cost:
              ESolutionProductCost[
                getEnumKeyByValue(ESolutionProductCost, product.cost)
              ],
          })) || [];

      // get default content flag (real-time only)
      const isOtherContent: boolean = Boolean(
        solutionData.otherContent?.find((code) => code === technique.code)
      );

      // assign products to the sub techniques
      const subTechniques = technique.subTechniques.map((subTechnique) => {
        const subTechniqueProducts = solutionData.techniqueProducts.find(
          (techniqueProduct) => techniqueProduct.code === subTechnique.code
        )?.products;
        const subTechniqueFilteredProducts: ISolutionProduct[] =
          subTechniqueProducts
            ?.filter((tp) => tp.tactic === tactic.shortName)
            .map((product) => ({
              ...product,
              cost:
                ESolutionProductCost[
                  getEnumKeyByValue(ESolutionProductCost, product.cost)
                ],
            })) || [];
        return {
          ...subTechnique,
          hasProducts: subTechniqueFilteredProducts.length > 0,
          products: subTechniqueFilteredProducts,
        };
      });

      return {
        ...technique,
        subTechniques,
        isOtherContent,
        hasProducts: techniqueHasProducts(products, subTechniques),
        products,
      };
    });

    // filter out techniques and sub-techniques with no products when not show all
    if (!payload.showAll) {
      techniquesWithProducts = techniquesWithProducts
        .filter((t) => t.hasProducts)
        .map((t) => ({
          ...t,
          subTechniques: t.subTechniques.filter((st) => st.hasProducts),
        }));
    }

    // package together as full tactic
    return {
      shortName: tactic.shortName,
      name: tactic.name,
      description: tactic.description,
      modalDescription: tactic.modalDescription,
      techniques: techniquesWithProducts.sort(sortTechniques),
    };
  });

  // package together as full solution
  return {
    reference: solutionData.reference,
    name: solutionData.name,
    description: solutionData.description,
    colors: solutionData.colors,
    tactics,
    seo: solutionData.seo,
  };
}

/**
 * Sort function for techniques - sorts by covered/covered in other content to not covered, then by alphabetical order
 */
function sortTechniques(a: ITechnique, b: ITechnique): number {
  if (!a.hasProducts && (b.hasProducts || a.isOtherContent)) return 1;
  if ((a.hasProducts || b.isOtherContent) && !b.hasProducts) return -1;

  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;
}

/**
 * Check if a technique or it's sub-techniques have products
 * @param technique technique and sub-techniques to check
 */
function techniqueHasProducts(
  products: ISolutionProduct[],
  subTechniques: ITechnique[]
): boolean {
  if (products.length > 0) {
    return true;
  }

  for (const subtechnique of subTechniques) {
    if (subtechnique.products.length > 0) {
      return true;
    }
  }

  return false;
}
