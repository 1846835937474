import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { IAppData } from '../models/data/app-data';
import { IExportData } from '../models/data/export-data';
import { GetSolution } from '../store/actions/solution.action';
import {
  selectExportedSolutionData,
  selectSelectedSolution,
  selectSelectedSolutionReference,
} from '../store/selectors/solution.selector';
import { IAppState } from '../store/state/app.state';
import { take, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SolutionService {
  private _dataUrl = './assets/json/app-data.json';

  solution$ = this._store.pipe(select(selectSelectedSolution));

  exportData$ = new Observable<IExportData | null>();

  constructor(private _store: Store<IAppState>, private _http: HttpClient) {}

  /**
   * Fires off action to get the current solution
   * @param reference The solution reference
   * @param showAll Flag to determine if showing Covered / All techniques
   */
  getSolution(reference: string, showAll: boolean): void {
    this._store.dispatch(new GetSolution({ reference, showAll }));
  }

  /**
   * Get the current app data from the local json file
   */
  getAppData(): Observable<IAppData> {
    return this._http.get<IAppData>(this._dataUrl);
  }

  /**
   * Gets the current solution as exported data and forces a download of the json blob
   */
  downloadExport(): void {
    combineLatest([
      this._store.select(selectExportedSolutionData),
      this._store.select(selectSelectedSolutionReference),
    ])
      .pipe(
        filter((d) => Boolean(d)),
        take(1)
      )
      .subscribe(([data, ref]) => {
        const fileName =
          ref === 'sentinel'
            ? 'Sentinel_MITRE_Techniques.json'
            : 'ArcSight_MITRE_Techniques.json';
        const blobData = [JSON.stringify(data, null, 2)];

        // IE / Edge on windows
        if (window.navigator.msSaveBlob) {
          const blob = new Blob(blobData, {
            type: 'application/json;charset=utf-8;',
          });
          window.navigator.msSaveBlob(blob, fileName);
          // other browsers - create a hidden anchor to perform the download
        } else {
          const blob = new Blob(blobData, { type: 'application/octet-stream' });
          const downloadUrl = window.URL.createObjectURL(blob);
          const a: HTMLAnchorElement = document.createElement('a');
          document.body.appendChild(a);
          a.style.display = 'none';
          a.href = downloadUrl;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(downloadUrl);
          document.body.removeChild(a);
        }
      });
  }
}
