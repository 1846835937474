declare var OneTrust: any;

import { Component, OnInit } from '@angular/core';
import { COOKIE_POLICY_URL, PRIVACY_POLICY_URL } from '../../constants/solution.constants';
import { OneTrustService } from '../../services/onetrust.service';

@Component({
  selector: 'mitre-cookie-management',
  templateUrl: './cookie-management.component.html',
  styleUrls: ['./cookie-management.component.scss']
})
export class CookieManagementComponent implements OnInit {

  constructor(private oneTrustService: OneTrustService) { }

  ngOnInit(): void { }

  manageCookiePreferences = (): void => {
    this.oneTrustService.manageCookiePreferences();
  }

  goToCookiePolicyPage = (): void => {
    window.open(COOKIE_POLICY_URL);
  }

  goToPrivacyPolicyPage = (): void => {
    window.open(PRIVACY_POLICY_URL);
  }
}
