<a
  *ngIf="(colorScheme$ | async) as colorScheme"
  class="sub-techniques__expand-btn"
  [ngStyle]="{
    'border-bottom': show ? 'none' : null
  }"
  (click)="show = !show; toggleShow.emit(show)">
  <ux-icon
    *ngIf="!show"
    class="sub-techniques__expand-icon"
    name="add"
    [ngStyle]="{
      'color': state === ETacticColumnNodeState.Highlighted && hoverColorChangeEnabled && (isLayeredAnalytics$ | async) === false ? colorScheme.main : null
    }"
  ></ux-icon>
  <ux-icon
    *ngIf="show"
    class="sub-techniques__collapse-icon"
    name="subtract"
    [ngStyle]="{
      'color': state === ETacticColumnNodeState.Highlighted && hoverColorChangeEnabled && (isLayeredAnalytics$ | async) === false ? colorScheme.main : null
    }"
  ></ux-icon>
  <div class="sub-techniques__count">{{ count }}</div>
</a>
