// This is to let TypeScript know that OneTrust is a global variable coming from an external script
declare var OneTrust: any;

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class OneTrustService {
    constructor(){ }

    checkIfOneTrustIsAvailable = (timeout: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            // This method will check every 100ms if OneTrust object is available
            const checkInterval = setInterval(() => {

                // checks if OneTrust object is available and ToggleInfoDisplay method exists
                if (typeof OneTrust !== undefined && OneTrust.ToggleInfoDisplay) {
                    clearInterval(checkInterval);
                    resolve();
                }
            }, 100);
            
            // If OneTrust is not available for 5000ms (default value), promise will be rejected
            setTimeout(() => {
                clearInterval(checkInterval);
                reject('OneTrust is not available');
            }, timeout);
        });
    }

    manageCookiePreferences = (timeout: number = 5000): void => {
        this.checkIfOneTrustIsAvailable(timeout).then(() => {
            OneTrust.ToggleInfoDisplay();
        }).catch(error => {
            console.error('Error displaying cookie preferences', error);
        });
    }
}