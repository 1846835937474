<div cdkTrapFocus>

  <div class="modal-content">

    <header class="modal-header">
      <h4 *ngIf="title" id="modal-title" class="modal-title">{{title}}</h4>
      <ng-container *ngIf="titleTemplateRef" [ngTemplateOutlet]="titleTemplateRef"></ng-container>
      <button type="button" class="btn btn-link btn-icon close" (click)="hide()"  aria-label="Close">
        <ux-icon name="close" size="16px"></ux-icon>
      </button>
    </header>

    <section class="modal-body">
      <ng-container [ngTemplateOutlet]="contentTemplateRef"></ng-container>
    </section>
  </div>

</div>
