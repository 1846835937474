<div class="column-sub-node__spacer">
  <span *ngIf="firstNode"></span>
</div>
<div *ngIf="colorScheme"
  class="column-sub-node__container"
  [ngClass]="{
    'column-sub-node__not-covered': status === ETacticColumnNodeStatus.NotCovered,
    'column-sub-node__other_content': status === ETacticColumnNodeStatus.OtherContent
  }"
  [ngStyle]="{
    'background-color': state === ETacticColumnNodeState.Highlighted && (status === ETacticColumnNodeStatus.Covered || status === ETacticColumnNodeStatus.OtherContent) ? colorScheme.secondary : null
  }"
  [uxTooltip]="technique.name + ' (' + technique.code + ')'"
  tooltipDelay="350"
  tooltipClass="mitre-tooltip"
  [placement]="rightSidedNode ? 'left' : 'right'"
  (click)="showSubtechnique.emit(technique)"
>
  <ng-container
  *ngIf="status === ETacticColumnNodeStatus.Covered">
    <mitre-color-bars
      *ngIf="(barColors$ | async) as barColors" [colors]="barColors"
      height="59px"
    >
    </mitre-color-bars>
  </ng-container>
  <div class="column-sub-node__title_container">
    <h6  mitreTruncateText [truncateText]="technique.name"></h6>
  </div>
</div>
