import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mitre-color-bar',
  templateUrl: './color-bar.component.html',
  styleUrls: ['./color-bar.component.css']
})
export class ColorBarComponent implements OnInit {

  @Input() fillColor: string;

  @Input() height = '50px';

  constructor() { }

  ngOnInit(): void { }

}
