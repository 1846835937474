import { ESolutionActions, SolutionActions } from '../actions/solution.action';
import { initialSolutionState, ISolutionState } from '../state';

export const solutionReducers = (
  state = initialSolutionState,
  action: SolutionActions
): ISolutionState => {
  switch (action.type) {

    /**
     * Return current state with newly selected solution
     */
    case ESolutionActions.GetSolutionSuccess: {
      return {
        ...state,
        selectedSolution: action.payload
      }
    }

    /**
     * Return current state with the new app data and loaded flag
     */
    case ESolutionActions.GetAppDataSuccess: {
      return {
        ...state,
        appDataLoaded: true,
        appData: action.payload
      }
    }

    /**
     * Return the default state
     */
    default:
      return state;
  }
}
