<header class="mitre-header">
  <div class="logo-area">
    <div class="logo-image">

      <a [routerLink]="isSentinel ? ['/sentinel'] : ['/']" class="logo"><img alt="OpenText Logo"
          src="assets/images/Mitre_Opentext_logo.svg" /></a>
    </div>
    <div class="logo-divider">
      <img src="../../../../assets/images/divider-icon.svg" />
    </div>
    <div class="logo-product">ArcSight</div>

  </div>



  <nav class="mitre-header-main-nav">
    <ul>
      <li *ngFor="let item of items">
        <a href="{{ item.routerLink }}" [ngClass]="{
            'mitre-header-main-nav-link': true,
            'mitre-header-main-nav-link-active':
              item.routerLink === currentRoute ||
              (currentRoute === '/' &&
                item.routerLink.indexOf('layered-analytics') > -1)
          }">{{ item.title }}</a>
      </li>
    </ul>
  </nav>
  <div class="additional-links">
    <a *ngIf="!isSentinel" [routerLink]="['/sentinel']"
      class="btn btn-link btn-secondary btn-arcsight-sentinel-link">Looking for NetIQ Sentinel?</a>
    <a *ngIf="isSentinel" [routerLink]="['/']" class="btn btn-link btn-secondary btn-arcsight-sentinel-link">Looking for
      ArcSight?</a>
    <button type="button" href="#" class="btn btn-link btn-icon btn-info" (click)="showLandingModal($event)">
      <img src="../../../../assets/images/status-info.svg" />
    </button>
  </div>
</header>