import { ITechnique } from '../../models/solution';

export interface IModalState {
  current: string | null;
  technique: ITechnique | null;
  currentTactic?: string | null;
}

export const initialModalState: IModalState = {
  current: null,
  technique: null,
  currentTactic: null
}

export function getInitialModalState(): IModalState {
  return initialModalState;
}
