<div *ngIf="solution$ | async as solution" class="solution-container">
  <div
    class="solution-introduction"
    (uxResize)="onSolutionIntroResized($event)"
  >
    <div
      [ngClass]="{
        description: true,
        'solution-description__extra_color-bars': isLayeredAnalytics$ | async
      }"
    >
      <mitre-color-bars
        class="solution-color-bars"
        *ngIf="barColors$ | async as barColors"
        [colors]="(isLayeredAnalytics$ | async) ? barColors : [barColors[0]]"
        [rounded]="true"
        height="100%"
      ></mitre-color-bars>
      <div class="solution-introduction-content">
        <h2 i18n>{{ solution.name }}</h2>
        <div
          class="solution-description"
          i18n
          [mitreReadMoreLess]="solution.description"
        ></div>
      </div>
    </div>
    <div class="solution-actions-container">
      <mitre-actions-bar></mitre-actions-bar>
    </div>
  </div>
  <div class="gradient-overlay-left" *ngIf="showLeftGradient$ | async"></div>
  <mitre-solution-grid
    [solution]="solution"
    [gridMarginTop]="gridMarginTop"
    [gridWidth]="gridWidth"
  ></mitre-solution-grid>
  <div class="gradient-overlay-right" *ngIf="showRightGradient$ | async"></div>
  <mitre-legend
    *ngIf="barColors$ | async as barColors"
    [colors]="barColors"
  ></mitre-legend>
  <mitre-cookie-management class="manage-cookie-settings"></mitre-cookie-management>
  <ngx-json-ld
    *ngIf="structuredData$ | async as structuredData"
    [json]="structuredData"
  ></ngx-json-ld>
</div>
