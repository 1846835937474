<div class="actions">
  <button
    class="btn btn-icon btn-link"
    type="button"
    uxTooltip="All Techniques"
    tooltipDelay="350"
    tooltipClass="mitre-tooltip"
    placement="top"
    (click)="toggleAllTechniques()">
    <ux-icon name="iteration" [flipHorizontal]="true" [flipVertical]="true" class="mitre-flipped-both"></ux-icon>
  </button>
  <button
    class="btn btn-icon btn-link"
    type="button"
    uxTooltip="Sub-Techniques"
    tooltipDelay="350"
    tooltipClass="mitre-tooltip" placement="top"
    (click)="toggleSubTechniques()">
    <ux-icon name="chart-organization"></ux-icon>
  </button>
  <button
    class="btn btn-icon btn-link"
    type="button"
    uxTooltip="Export"
    tooltipDelay="350"
    tooltipClass="mitre-tooltip"
    placement="top"
    (click)="export()">
    <ux-icon name="upload"></ux-icon>
  </button>
</div>
