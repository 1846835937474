import { createSelector } from '@ngrx/store';
import { IExportData } from '../../models/data/export-data';
import { ITechnique } from '../../models/solution';
import { IAppState, IModalState } from '../state';

export interface ICurrent {
  name: string;
  technique?: ITechnique,
  currentTactic?: string;
  exportData?: IExportData;
}

const selectModalState = (state: IAppState) => state.modals;

/**
 * Get value representing if the Landing Page modal should be visible
 */
export const selectCurrentModal = createSelector(
  selectModalState,
  (state: IModalState): ICurrent => ({
    name: state?.current,
    technique: state?.technique,
    currentTactic: state?.currentTactic
  })
);
