import { IAppData } from '../../models/data/app-data';
import { ISolution } from '../../models/solution/solution';

export interface ISolutionState {
  selectedSolution: ISolution;
  appDataLoaded: boolean;
  appData: IAppData;
}

export const initialSolutionState: ISolutionState = {
  selectedSolution: null,
  appDataLoaded: false,
  appData: null
};

export function getInitialSolutionState(): ISolutionState {
  return initialSolutionState;
}
