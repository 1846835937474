import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ResizeDimensions } from '@ux-aspects/ux-aspects';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { DEFAULT_SOLUTION_REFERENCE, SOLUTION_REFERENCES } from '../../constants/solution.constants';
import { ISolution } from '../../models/solution/solution';
import { SolutionGridService } from '../../services/solution-grid.service';
import { TechniqueService } from '../../services/technique.service';
import { IAppState } from '../../store';
import { GetSolution } from '../../store/actions/solution.action';
import { selectSelectedSolution, selectSolutionMainColorByReferences, selectSolutionSeoData } from '../../store/selectors/solution.selector';

@Component({
  selector: 'mitre-page-component',
  templateUrl: './solution.component.html',
  styleUrls: [ './solution.component.scss' ]
})
export class SolutionComponent implements OnInit, OnDestroy {
  /**
   * Subject for garbage collection
   */
  private _destroy$ = new Subject<void>();

  /**
   * Grid margin top
   */
  gridMarginTop = 0;

  /**
   * Grid width
   */
  gridWidth = 0;

  /**
   * Observable to get the current solution
   */
  solution$: Observable<ISolution> = this._store.pipe(select(selectSelectedSolution));

  /**
   * Observable to check if the current solution is layered analytics
   */
  isLayeredAnalytics$: Observable<boolean> = this._techniqueService.isLayeredAnalytics$
    .pipe(takeUntil(this._destroy$));

  /**
   * Stores the previous reference loaded (for checking if to reset options in view on new page)
   */
  previousRef: string;

  /**
   * Get the bar colors based on the solution
   */
  barColors$: Observable<string[]> = this._store.pipe(
    select(selectSelectedSolution),
    switchMap(solution => {
      this.gridWidth = solution.tactics.length * 200;
      this.showRightGradient$.next(this.gridWidth - this._window.innerWidth > 0);

      if (solution.reference === DEFAULT_SOLUTION_REFERENCE) {
        const references = SOLUTION_REFERENCES.filter(ref => ref !== DEFAULT_SOLUTION_REFERENCE);
        return this._store.pipe(select(selectSolutionMainColorByReferences, { references }));
      }

      return of([solution.colors.main, solution.colors.secondary]);
    }),
    takeUntil(this._destroy$)
  );

  /** Behaviour Subject to store Structured Data Schema values */
  structuredData$ = new BehaviorSubject<{ [key: string]: string }>(null);

  showLeftGradient$ = new BehaviorSubject<boolean>(false);

  showRightGradient$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _store: Store<IAppState>,
    private readonly _route: ActivatedRoute,
    private readonly _techniqueService: TechniqueService,
    private readonly _solutionGridService: SolutionGridService,
    private readonly _title: Title,
    private readonly _meta: Meta,
    private readonly _renderer2: Renderer2,
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(Window)private readonly _window: Window
  ) {
    this._store.pipe(
      select(selectSolutionSeoData),
      filter(seoData => Boolean(seoData)),
      takeUntil(this._destroy$)
    ).subscribe(seoData => {
      // Set page title
      this._title.setTitle(seoData.title);
      // Set meta tags
      seoData.metaTags.forEach(metaTag => this._meta.updateTag(metaTag));
      // Set link tag
      this.createLinkTag(seoData.linkTag);
      // Set structured data schema to populate in the page as script
      this.structuredData$.next(seoData.structuredData);
    });
  }

  /**
   * Watches for changes in the routing and updates the solution accordingly
   */
  ngOnInit(): void {
    this._route.params.subscribe(routeParams => {
      if (routeParams.ref) {
        this._solutionGridService.showSubTechniques$.next(false);
        this._solutionGridService.showAll$.next(false);
        this.previousRef = routeParams.ref;
      }
    });

    this._solutionGridService.showAll$
      .pipe(takeUntil(this._destroy$))
      .subscribe(showAll => this.loadSolution(this._route.snapshot.params.ref, showAll));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
   * Load the correct solution
   * @param solutionReference the solution reference to load
   */
  loadSolution(solutionReference: string, showAll: boolean): void {
    this._store.dispatch(new GetSolution({ reference: solutionReference, showAll }));
  }

  /**
   * Creates link tag in the document head
   */
  createLinkTag(linkTag: { href: string, rel: string }): void {
    this.removeExistingLinkTag(linkTag.rel);

    const link = this._document.createElement('link');
    link.href = linkTag.href;
    link.rel = linkTag.rel;
    this._renderer2.appendChild(this._document.head, link);
  }

  /**
   * Remove existing link if it exists
   */
  removeExistingLinkTag(rel: string): void {
    const link = this._document.head.querySelector(`[rel="${rel}"]`);

    if (link) {
      this._renderer2.removeChild(this._document.head, link);
    }
  }

  /**
   * Resize event for solution description
   */

  onSolutionIntroResized(event: ResizeDimensions): void {
    this.gridMarginTop = event.height;
  }

  /**
   * Track scroll to hide and reveal side gradients
   */
  @HostListener('window:scroll', ['$event'])
  windowScrolling(): void {
    if (this._window.pageXOffset > 0 && this._window.pageXOffset !== this.gridWidth) {
      this.showLeftGradient$.next(true);
    } else {
      this.showLeftGradient$.next(false);
    }

    if (this._window.pageXOffset < (this.gridWidth - this._window.innerWidth) && this.gridWidth > this._window.innerWidth) {
      this.showRightGradient$.next(true);
    } else {
      this.showRightGradient$.next(false);
    }
  }
}
