import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { solutionReducers } from './solution.reducer';
import { modalReducers } from './modal.reducer';

export const appReducers: ActionReducerMap<IAppState, any> = {
  router: routerReducer,
  solutions: solutionReducers,
  modals: modalReducers
}
