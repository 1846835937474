import { Action } from '@ngrx/store';
import { IModalPayload } from './modal-payload';

export enum EModalActions {
  Show = '[Modal] Show Modal',
  Hide = '[Modal] Hide Modal',
}

export enum EModalName {
  Landing = 'landing',
  Export = 'export',
  Technique = 'technique'
}

export class ShowModal implements Action {
  public readonly type = EModalActions.Show;
  constructor(public payload: IModalPayload) {}
}

export class HideModal implements Action {
  public readonly type = EModalActions.Hide;
}


export type ModalActions = ShowModal | HideModal;
