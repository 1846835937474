import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import {
  AccessibilityModule,
  AccordionModule,
  IconModule,
  PageHeaderModule,
  ResizeModule,
  TooltipModule,
} from '@ux-aspects/ux-aspects';
import { ActionsBarComponent } from './components/actions-bar/actions-bar.component';
import { ColorBarComponent } from './components/color-bar/color-bar.component';
import { ColorBarsComponent } from './components/color-bars/color-bars.component';
import { HeaderComponent } from './components/header/header.component';
import { LegendComponent } from './components/legend/legend.component';
import { SolutionGridComponent } from './components/solution-grid/solution-grid.component';
import { SolutionComponent } from './components/solution/solution.component';
import { TacticColumnNodeButtonComponent } from './components/tactic-column-node/tactic-column-node-button.component';
import { TacticColumnNodeComponent } from './components/tactic-column-node/tactic-column-node.component';
import { TacticColumnSubNodeToggleComponent } from './components/tactic-column-sub-node-toggle/tactic-column-sub-node-toggle.component';
import { TacticColumnSubNodeComponent } from './components/tactic-column-sub-node/tactic-column-sub-node.component';
import { TacticColumnComponent } from './components/tactic-column/tactic-column.component';
import { ReadMoreLessDirective } from './directives/read-more-less.directive';
import { TruncateTextDirective } from './directives/truncate-text.directive';
import { ReplacePipe } from './pipes/replace.pipe';
import { SolutionGridService } from './services/solution-grid.service';
import { SolutionService } from './services/solution.service';
import { TechniqueService } from './services/technique.service';
import { RouterModule } from '@angular/router';
import { CookieManagementComponent } from './components/cookie-management/cookie-management.component';
import { OneTrustService } from './services/onetrust.service';

@NgModule({
  imports: [
    AccessibilityModule,
    PageHeaderModule,
    HttpClientModule,
    CommonModule,
    TooltipModule,
    IconModule,
    AccordionModule,
    NgxJsonLdModule,
    ResizeModule,
    RouterModule,
  ],
  exports: [
    HeaderComponent,
    SolutionGridComponent,
    ColorBarComponent,
    ColorBarsComponent,
    CookieManagementComponent
  ],
  declarations: [
    HeaderComponent,
    SolutionComponent,
    SolutionGridComponent,
    TacticColumnComponent,
    TacticColumnNodeComponent,
    TacticColumnNodeButtonComponent,
    TacticColumnSubNodeComponent,
    TacticColumnSubNodeToggleComponent,
    ReplacePipe,
    ColorBarComponent,
    ColorBarsComponent,
    LegendComponent,
    ActionsBarComponent,
    ReadMoreLessDirective,
    TruncateTextDirective,
    CookieManagementComponent
  ],
  providers: [
    SolutionService,
    TechniqueService,
    SolutionGridService,
    { provide: Window, useValue: window },
    OneTrustService
  ],
})
export class SharedModule {}
