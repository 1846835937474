import { Action } from '@ngrx/store';
import { ISolution } from '../../models/solution';
import { IGetSolutionPayload } from './get-solution-payload';
import { IAppData } from '../../models/data/app-data';

export enum ESolutionActions {
  GetSolution = '[Solution] Get Solution',
  GetSolutionSuccess = '[Solution] Get Solution Success',
  GetAppData = '[AppData] Get App Data',
  GetAppDataSuccess = '[AppData] Get App Data Success',
  GetAppDataFailed = '[Solution] Get App Data Failed',
}

export class GetSolution implements Action {
  public readonly type = ESolutionActions.GetSolution;

  constructor(public payload: IGetSolutionPayload) {}
}

export class GetSolutionSuccess implements Action {
  public readonly type = ESolutionActions.GetSolutionSuccess;

  constructor(public payload: ISolution) {}
}

export class GetAppData implements Action {
  public readonly type = ESolutionActions.GetAppData;
}

export class GetAppDataSuccess implements Action {
  public readonly type = ESolutionActions.GetAppDataSuccess;

  constructor(public payload: IAppData) {}
}

export class GetAppDataFailed implements Action {
  public readonly type = ESolutionActions.GetAppDataFailed;
}


export type SolutionActions = GetSolution | GetSolutionSuccess | GetAppData | GetAppDataSuccess | GetAppDataFailed;
