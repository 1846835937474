<div [ngClass]="{
  'column-node': true,
  'column-node__other-content': status === ETacticColumnNodeStatus.OtherContent,
  'column-node__not-covered': status === ETacticColumnNodeStatus.NotCovered,
  'border__none-right': lastNode,
  'column-node__side-btn': technique.subTechniques.length > 0
}"
[ngStyle]="{
  'background-color': state === ETacticColumnNodeState.Highlighted && status !== ETacticColumnNodeStatus.NotCovered ? colorScheme.secondary : null,
  'border-bottom': showSubTechniques ? 'none' : null
}"
[uxTooltip]="technique.name + ' (' + technique.code + ')'"
tooltipDelay="350"
tooltipClass="mitre-tooltip"
[placement]="rightSidedNode ? 'left' : 'right'"
>
<span *ngIf="status === ETacticColumnNodeStatus.OtherContent" [ngStyle]="{ 'border-top-color': colorScheme.main }"></span>
<h5 mitreTruncateText [truncateText]="technique.name"></h5>
</div>
