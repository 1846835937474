import { createSelector } from '@ngrx/store';
import {
  DEFAULT_SOLUTION_REFERENCE,
  SOLUTION_REFERENCES,
} from '../../constants/solution.constants';
import { IAppState, ISolutionState } from '../state';
import { exportSolutionData } from './export';

const selectSolutionsState = (state: IAppState) => state.solutions;
export const defaultColorScheme = {
  main: '#506670',
  secondary: '#65818E',
  otherContent: '#65818E',
};

/**
 * Select the current solution
 */
export const selectSelectedSolution = createSelector(
  selectSolutionsState,
  (state: ISolutionState) => state.selectedSolution
);

/***
 * Select the reference for the current solution
 */
export const selectSelectedSolutionReference = createSelector(
  selectSolutionsState,
  (state: ISolutionState) => state.selectedSolution?.reference
);

/***
 * Select the color scheme for the current solution
 */
export const selectSelectedSolutionColorScheme = createSelector(
  selectSolutionsState,
  (state: ISolutionState) => state.selectedSolution.colors || defaultColorScheme
);

/***
 * Select the color scheme for the current solution by reference in the correct order
 */
export const selectSolutionColorSchemeByReference = createSelector(
  selectSolutionsState,
  (state: ISolutionState, props: { reference: string }) =>
    state.appData.solutionData.find((sd) => sd.reference === props.reference)
      ?.colors || defaultColorScheme
);

/***
 * Select the color scheme for the current solution by reference in the correct order
 */
export const selectSolutionMainColorByReferences = createSelector(
  selectSolutionsState,
  (state: ISolutionState, props: { references: string[] }) => {
    const mainColors = SOLUTION_REFERENCES.filter(
      (ref) => props.references.indexOf(ref) > -1
    ).map(
      (ref) =>
        state.appData.solutionData.find((sd) => sd.reference === ref)?.colors
          ?.main
    );

    return mainColors.length > 0 ? mainColors : [defaultColorScheme.main];
  }
);

export const selectMainColorByProductReferences = createSelector(
  selectSolutionsState,
  (state: ISolutionState, props: { references: string[] }) => {
    const mainColors = props.references.map(
      (ref) =>
        state.appData.solutionData.find((sd) => sd.reference === ref)?.colors
          ?.main
    );

    return mainColors.length > 0 ? mainColors : [defaultColorScheme.main];
  }
);

/**
 * Select the app source data
 */
export const selectAppData = createSelector(
  selectSolutionsState,
  (state: ISolutionState) => state.appData
);

/**
 * Select flag that determines if the app data is loaded
 */
export const selectAppDataLoaded = createSelector(
  selectSolutionsState,
  (state: ISolutionState) => state.appDataLoaded
);

/**
 * Selector used to populate the modal tactic data
 */
export const selectTactic = createSelector(
  selectSolutionsState,
  (state: ISolutionState, props: { shortName: string }) => {
    const currentTactic = state.appData?.mitreData?.tactics?.find(
      (t) => t.shortName === props.shortName
    );
    return currentTactic
      ? {
          name: currentTactic.name,
          description: currentTactic.modalDescription,
        }
      : null;
  }
);

/***
 * Select the color scheme for the current solution by reference in the correct order
 */
export const selectRelatedTactics = createSelector(
  selectSolutionsState,
  (
    state: ISolutionState,
    props: { currentCode: string; currentTactic: string }
  ) =>
    state.selectedSolution
      ? state.selectedSolution.tactics
          .filter(
            (tactic) =>
              tactic.shortName !== props.currentTactic &&
              tactic.techniques.find((t) => t.code === props.currentCode)
          )
          .map((tactic) => tactic.name)
          .join(', ')
      : null
);

/**
 * Select a solution for export
 */
export const selectExportedSolutionData = createSelector(
  selectSolutionsState,
  (state: ISolutionState) => {
    const color =
      state.selectedSolution.reference === DEFAULT_SOLUTION_REFERENCE
        ? state.appData.solutionData.find(
            (solution) => solution.reference === SOLUTION_REFERENCES[1]
          )?.colors?.main
        : state.selectedSolution.colors?.main;
    return exportSolutionData(state.selectedSolution, color);
  }
);

/**
 * Select a solution's SEO data
 */
export const selectSolutionSeoData = createSelector(
  selectSolutionsState,
  (state: ISolutionState) => {
    return state.selectedSolution?.seo;
  }
);
