import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ETacticColumnNodeState } from '../components/tactic-column-node';

@Injectable()
export class SolutionGridService {
  /**
   * Event to trigger cross column highlighting
   */
  readonly changeAssociatedNodesEvent$ = new BehaviorSubject<{ code: string, tactics: string[], state: ETacticColumnNodeState }>(null);

  /**
   * Store whether to show all / covered only
   */
  showAll$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * expand/collapse all sub-techniques
   */
  showSubTechniques$ = new BehaviorSubject<boolean>(false);
}
