import { Component, OnDestroy, Input, Output, EventEmitter, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ModalContentComponent } from '../modal-content/modal-content.component';
import { Store } from '@ngrx/store';
import { HideModal } from '../../store/actions/modal.action';
import { ITechnique } from '../../models/solution';

@Component({
  selector: 'mitre-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnDestroy, AfterViewInit {

  @Input() title: string;

  @Input() size: ModalSize;

  @Output() modalDismiss: EventEmitter<void> = new EventEmitter();

  @ViewChild('title', { static: false }) titleTemplateRef: TemplateRef<void>;
  @ViewChild('content', { static: false }) contentTemplateRef: TemplateRef<void>;

  private modalRef: BsModalRef;
  private readonly subscription: Subscription;

  constructor(private readonly modalService: BsModalService, private store: Store) {
    this.subscription = this.modalService.onHide.subscribe((val) => this.handleOnHide());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
  }

  private handleOnHide() {
    this.store.dispatch(new HideModal());
    this.modalDismiss.next();
  }

  private getModalName(opts) {
    const name = opts?.name || 'generic';
    return `${name}-modal`;
  }

  show(opts?: ModalShowOptions): void {
    this.modalRef = this.modalService.show(ModalContentComponent, {
      initialState: {
        title: this.title,
        contentTemplateRef: this.contentTemplateRef,
        titleTemplateRef: this.titleTemplateRef,
        technique: opts?.technique,
        currentTactic: opts?.currentTactic
      },
      class: `${this.getModalName(opts)} ${this.size}`
    });
  }

  hide(): void {
    this.modalRef.hide();
  }

}

interface ModalShowOptions {
  name: string;
  component?: any;
  technique?: ITechnique;
  currentTactic?: string;
}


type ModalSize = 'modal-xlg' | 'modal-lg';
