import { IExportData } from '../../models/data/export-data';
import { IExportTechnique } from '../../models/data/export-technique';
import { ISolution } from '../../models/solution/solution';
import { ITechnique } from '../../models/solution/technique';
import { ISolutionProduct } from '../../models/solution';

/**
 * Export a solution to the Attack Mitre Navigator friendly format
 */
export function exportSolutionData(solution: ISolution, color: string): IExportData {
  const data = initExportData();
  data.name = `Micro Focus ArcSight - ${solution.name}`;
  data.description = `${solution.name} techniques covered by Micro Focus ArcSight`;
  data.techniques = getTechniquesFromTactics(solution, color);
  return data;
}

function initExportData(): IExportData {
  return {
    name: '',
    version: '3.0',
    domain: 'mitre-enterprise',
    description: '',
    filters: {
      'stages': ['act'],
      'platforms': [
        'Windows',
        'Linux',
        'macOS'
      ]
    },
    sorting: 0,
    layout: {
      layout: 'side',
      showID: true,
      showName: true
    },
    hideDisabled: false,
    techniques: [],
    gradient: {
      colors: [
        '#ff6666',
        '#ffe766',
        '#8ec843'
      ],
      minValue: 0,
      maxValue: 100,
    },
    legendItems: [],
    metadata: [],
    showTacticRowBackground: false,
    tacticRowBackground: '#dddddd',
    selectTechniquesAcrossTactics: true,
    selectSubtechniquesWithParent: false
  }
};

/**
 * Create a flat array of all techniques and sub-techniques in the tactics of a solution
 * @param solution The current solution
 * @param color The color to assign to a technique
 */
export function getTechniquesFromTactics(solution: ISolution, color: string): IExportTechnique[] {
  let exportedTechniques: IExportTechnique[] = [];

  for (const tactic of solution.tactics) {
    for (const technique of tactic.techniques) {
      exportedTechniques.push(techniqueToExportedTechnique(technique, tactic.shortName, color));

      if (technique.subTechniques?.length > 0) {
        exportedTechniques = exportedTechniques.concat(technique.subTechniques.map(st => techniqueToExportedTechnique(st, tactic.shortName, color)));
      }
    }
  }

  return exportedTechniques;
}

/**
 * Get a Mitre Attack Navigator friendly technique object
 * @param technique The technique to map
 * @param tactic the current tactic name
 * @param color the color to assign
 */
export function techniqueToExportedTechnique(technique: ITechnique, tactic: string, color: string): IExportTechnique {
  return {
    techniqueID: technique.code,
    tactic,
    score: technique.hasProducts ? 75 : 50,
    color,
    comment: '',
    enabled: technique.hasProducts,
    showSubtechniques: false,
    metadata: createTechniqueMetadata(technique.products)
  };
}

function createTechniqueMetadata(products: ISolutionProduct[]): any[] {
  return [...products].sort().map((p, i) => ({
    name: 'Product',
    value: p.name
  }));
}
