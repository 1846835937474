import { Component, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ISolutionColorScheme } from '../../models/solution';
import { TechniqueService } from '../../services/technique.service';
import { IAppState } from '../../store';
import { selectSelectedSolutionColorScheme } from '../../store/selectors/solution.selector';
import { ETacticColumnNodeState } from '../tactic-column-node/tactic-column-node-state';

@Component({
  selector: 'mitre-tactic-column-sub-node-toggle',
  templateUrl: './tactic-column-sub-node-toggle.component.html',
  styleUrls: [ './tactic-column-sub-node-toggle.component.scss' ]
})
export class TacticColumnSubNodeToggleComponent implements OnDestroy {
  /**
   * Subject for garbage collection
   */
  private _destroy$ = new Subject<void>();

  /**
   * Reference to state enum for accessing in the view
   */
  ETacticColumnNodeState = ETacticColumnNodeState;

  /**
   * Boolean to store state of toggle
   */
  @Input() show = false;

  /**
   * Count of techniques to display
   */
  @Input() count = 0;

  /**
   * Toggles if color change is used (for Covered status only)
   */
  @Input() hoverColorChangeEnabled = false;

  /**
   * Fire event to pass toggle value
   */
  @Output() toggleShow = new EventEmitter<boolean>();

  /**
   * Event emitted on mouse enter
   */
  @Output()
  mouseEnter = new EventEmitter();

  /**
   * Event emitted on mouse leave
   */
  @Output()
  mouseLeave = new EventEmitter();

  state: ETacticColumnNodeState = ETacticColumnNodeState.Active;

  colorScheme$: Observable<ISolutionColorScheme> = this._store.pipe(select(selectSelectedSolutionColorScheme));

  isLayeredAnalytics$: Observable<boolean> = this._techniqueService.isLayeredAnalytics$
    .pipe(takeUntil(this._destroy$));

  /**
   * Listen for mouse enter event
   */
  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: MouseEvent) {
    event.preventDefault();
    this.state = ETacticColumnNodeState.Highlighted;
    this.mouseEnter.emit(null);
  }

  /**
   * Listen for mouse leave event
   */
  @HostListener('mouseleave', ['$event'])
  onMouseLeave(event: MouseEvent) {
    event.preventDefault();
    this.state = ETacticColumnNodeState.Active;
    this.mouseLeave.emit(null);
  }

  constructor(
    private _store: Store<IAppState>,
    private _techniqueService: TechniqueService
  ) { }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
