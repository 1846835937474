import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SolutionGridService } from '../../services/solution-grid.service';
import { IAppState } from '../../store';
import { EModalName, ShowModal } from '../../store/actions/modal.action';

@Component({
  selector: 'mitre-actions-bar',
  templateUrl: './actions-bar.component.html',
  styleUrls: ['./actions-bar.component.scss']
})
export class ActionsBarComponent {

  constructor(
    private _store: Store<IAppState>,
    private _solutionGridService: SolutionGridService
  ) { }

  toggleAllTechniques(): void {
    this._solutionGridService.showAll$.next(!this._solutionGridService.showAll$.value);
  }

  toggleSubTechniques(): void {
    this._solutionGridService.showSubTechniques$.next(!this._solutionGridService.showSubTechniques$.value)
  }

  export(): void {
    this._store.dispatch(new ShowModal({
      name: EModalName.Export
    }));
  }

}
