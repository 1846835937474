<p class="products__label">Products</p>
<ul class="products__items">
  <li class="" *ngFor="let product of products; let i = index">
    <ng-container *ngIf="hasUrl(product); else nonLinkTemplate">
      <a
        class="products__item"
        href="{{ hasUrl(product) ? product.url : null }}"
        target="microfocus-product"
        title="{{ product.name }}"
      >
        <mitre-color-bars
          [colors]="getColorArrayForIndex(i)"
          height="52px"
        ></mitre-color-bars>
        <div class="item__details">
          <p>{{ product.name }}</p>
          <sup *ngIf="product.cost === ESolutionProductCost.Free">{{
            product.cost | lowercase
          }}</sup>
        </div>
        <ux-icon
          *ngIf="hasUrl(product)"
          class="product-external-icon"
          name="share"
        ></ux-icon>
      </a>
    </ng-container>

    <ng-template #nonLinkTemplate>
      <div class="products__item" title="{{ product.name }}">
        <mitre-color-bars
          [colors]="getColorArrayForIndex(i)"
          height="52px"
        ></mitre-color-bars>
        <div class="item__details">
          <p>{{ product.name }}</p>
          <sup *ngIf="product.cost === ESolutionProductCost.Free">{{
            product.cost | lowercase
          }}</sup>
        </div>
      </div>
    </ng-template>
  </li>
</ul>
