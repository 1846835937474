import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ESolutionProductCost, ISolutionProduct } from '../../models/solution';
import { IAppState } from '../../store';
import { selectMainColorByProductReferences } from '../../store/selectors/solution.selector';

@Component({
  selector: 'mitre-technique-product-list',
  templateUrl: './technique-product-list.component.html',
  styleUrls: ['./technique-product-list.component.scss'],
})
export class TechniqueProductListComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  private readonly _invalidUrl = 'none';

  ESolutionProductCost = ESolutionProductCost;

  @Input() products: ISolutionProduct[];
  colors: string[];

  constructor(private store: Store<IAppState>) {}

  ngOnInit(): void {
    const references = this.products.map((product) => product.solution);
    this.store
      .pipe(select(selectMainColorByProductReferences, { references }))
      .pipe(takeUntil(this._destroy$))
      .subscribe((colors) => (this.colors = colors));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getColorArrayForIndex(index) {
    const color = this.colors[index];
    return [color];
  }

  hasUrl(product: ISolutionProduct) {
    return product.url && product.url?.toLowerCase() !== this._invalidUrl;
  }
}
