import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TabsetComponent } from '@ux-aspects/ux-aspects';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { ITechnique } from '../../models/solution';
import { ModalService } from '../../services/modal.service';
import { TechniqueService } from '../../services/technique.service';
import { selectRelatedTactics, selectTactic } from '../../store/selectors/solution.selector';

@Component({
  selector: 'mitre-technique-modal-content',
  templateUrl: './technique-modal-content.component.html',
  styleUrls: ['./technique-modal-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TechniqueModalContentComponent implements OnDestroy, OnChanges {

  private _destroy$ = new Subject<void>();

  @Input() technique!: ITechnique;

  @Input() currentTactic!: string;

  @ViewChild('tabset') tabset: TabsetComponent;

  technique$ = new BehaviorSubject<ITechnique>(null);

  currentTactic$ = new BehaviorSubject<string>(null);

  subtechnique$: BehaviorSubject<ITechnique> = new BehaviorSubject(null);

  isLayeredAnalytics$: Observable<boolean> = this.techniqueService.isLayeredAnalytics$.pipe(takeUntil(this._destroy$));

  isContentTabVisible$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  relatedTactics$: Observable<string | null> =
    combineLatest([
      this.technique$,
      this.currentTactic$
    ]).pipe(
      filter(([technique, currentTactic]) => Boolean(technique && currentTactic)),
      takeUntil(this._destroy$),
      switchMap(([technique, currentTactic]: [ITechnique, string]) => this.store.pipe(
        select(selectRelatedTactics, { currentCode: technique.code, currentTactic }))
      )
    );

  description$ = this.currentTactic$.pipe(
    filter(t => Boolean(t)),
    takeUntil(this._destroy$),
    switchMap(currentTactic => this.store.pipe(select(selectTactic, { shortName: currentTactic }))));

  constructor(
    private modalService: ModalService,
    private techniqueService: TechniqueService,
    private store: Store
  ) {
    this.modalService.selectedSubtechnique$
      .pipe(
        takeUntil(this._destroy$),
        map((sub) => {
          this.subtechnique$.next(sub);
          return sub;
        })
      )
      .subscribe((sub) => {
        if (!sub) {
          this.tabset?.selectTab(0);
        }

        this.isContentTabVisible$.next(this.hasContentToDisplay());
      });

    this.isContentTabVisible$.pipe(takeUntil(this._destroy$));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.technique && changes.technique.currentValue !== changes.technique.previousValue) {
      this.technique$.next(changes.technique.currentValue);
    }

    if (changes.currentTactic && changes.currentTactic.currentValue !== changes.currentTactic.previousValue) {
      this.currentTactic$.next(changes.currentTactic.currentValue);
    }

    this.isContentTabVisible$.next(this.hasContentToDisplay());
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public get subtechniqueCount() {
    return `Sub-techniques (${this.technique?.subTechniques?.length})`;
  }

  public selectSubtechnique(subtechnique: ITechnique) {
    this.modalService.setSelectedSubTechnique(subtechnique);
  }

  public hasContentToDisplay(): boolean {
    const hasSubtechnique = this.subtechnique$.value;

    const techniqueHasContentToShow = (
      this.technique?.products.length > 0 ||
      this.technique?.subTechniques.length > 0 ||
      this.technique?.hasProducts
    );

    const subtechniqueHasContentToShow = (
      this.subtechnique$.value?.hasProducts ||
      this.subtechnique$.value?.products.length > 0
    );

    if (
      (!hasSubtechnique && techniqueHasContentToShow) ||
      (hasSubtechnique && subtechniqueHasContentToShow)
    ) {
      return true;
    }

    return false;
  }
}
