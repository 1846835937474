import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mitre-color-bars',
  templateUrl: './color-bars.component.html',
  styleUrls: ['./color-bars.component.css']
})
export class ColorBarsComponent {
  @Input() colors: string[] = [];

  @Input() height = '50px';

  @Input() rounded = false;

  constructor() {}
}
