import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DEFAULT_SOLUTION_REFERENCE, SOLUTION_REFERENCES } from '../constants/solution.constants';

@Injectable()
export class SolutionGuard implements CanActivate {

  constructor(private readonly _router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const currentReference = route.params.ref.toLowerCase();

    // redirect to first solution if does not exist
    if (SOLUTION_REFERENCES.indexOf(currentReference) === -1) {
      this._router.navigate([DEFAULT_SOLUTION_REFERENCE]);
    }

    return true;
  }
}
