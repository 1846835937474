import { EModalActions, ModalActions } from '../actions/modal.action';
import { IModalState, initialModalState } from '../state';

export const modalReducers = (
  state = initialModalState,
  action: ModalActions
): IModalState => {
  switch (action.type) {
    case EModalActions.Show: {
      return {
        ...state,
        current: action.payload.name,
        technique: action.payload.technique || null,
        currentTactic: action.payload.currentTactic || null
      }
    }
    case EModalActions.Hide: {
      return {
        ...state,
        current: null,
        technique: null,
        currentTactic: null
      }
    }
    default:
      return state;
  }
}
