<div class="tab-container clearfix">
  <ux-tabset [minimal]="true" aria-label="Technique" #tabset>
    <ux-tab *ngIf="isContentTabVisible$ | async" [active]="(isContentTabVisible$ | async)">
      <ng-template uxTabHeading>
        <span i18n>Content</span>
      </ng-template>
      <div class="scrollable">
        <ng-container *ngIf="(subtechnique$ | async) as sub; else subtechniqueContentTemplate">
          <mitre-technique-product-list *ngIf="sub?.products.length > 0" [products]="sub?.products"></mitre-technique-product-list>
        </ng-container>
        <ng-template #subtechniqueContentTemplate>
          <!-- Subtechniques -->
          <mitre-technique-product-list class="parent__products" *ngIf="technique?.products.length > 0" [products]="technique?.products"></mitre-technique-product-list>
          <ux-accordion [collapseOthers]="true" *ngIf="technique?.subTechniques.length > 0">

            <ux-accordion-panel class="accordion-chevron" [expanded]="true" [heading]="subtechniqueCount">
              <ul class="subtechniques__items">
                <li *ngFor="let subtechnique of technique?.subTechniques" class="subtechniques__item">
                  <div class="code">
                    <a href="{{subtechnique.url}}" title="{{subtechnique.name}}" target="_new" class="link-external">{{subtechnique.code}}</a>
                  </div>
                  <div class="name" (click)="selectSubtechnique(subtechnique)">{{subtechnique.name}}</div>
                </li>
              </ul>
            </ux-accordion-panel>
          </ux-accordion>
        </ng-template>

        <ng-template #techniqueProductsContentTemplate>
          <mitre-technique-product-list class="parent__products" *ngIf="technique?.products.length > 0" [products]="technique?.products"></mitre-technique-product-list>
        </ng-template>
      </div>
    </ux-tab>

    <ux-tab [active]="(isContentTabVisible$ | async) === false">
      <ng-template uxTabHeading>
        <span i18n>Information</span>
      </ng-template>

      <div class="scrollable">
        <ng-container *ngIf="(subtechnique$ | async) as sub; else techniqueHeaderTemplate">
          <header class="technique__info">
            <div class="info__row">
              <div class="info__group">
                <div i18n>ID:</div>
                <div class="info__code"><a href="{{sub?.url}}" target="mitre-attack" class="link-external">{{sub?.code}}</a></div>
              </div>
              <div class="info__group">
                <div class="info__sub_of" i18n>Sub-technique of:</div>
                <div class="info__code"><a href="{{technique?.url}}" target="mitre-attack" class="link-external">{{technique?.code}}</a></div>
              </div>
            </div>
            <ng-container *ngTemplateOutlet="tacticTemplate"></ng-container>
            <ng-container *ngTemplateOutlet="relatedTacticsTemplate"></ng-container>
          </header>
        </ng-container>

        <ng-template #techniqueHeaderTemplate>
          <header class="technique__info">
            <div class="info__row">
              <div class="info__group">
                <div i18n>ID:</div>
                <div class="info__code"><a href="{{(technique$ | async)?.url}}" target="mitre-attack" class="link-external">{{(technique$ | async)?.code}}</a></div>
              </div>
            </div>
            <ng-container *ngTemplateOutlet="tacticTemplate"></ng-container>
            <ng-container *ngTemplateOutlet="relatedTacticsTemplate"></ng-container>
          </header>
        </ng-template>

        <div class="technique__description" [innerHTML]=" (subtechnique$ | async)?.description || (technique$ | async)?.description"></div>
      </div>
    </ux-tab>
  </ux-tabset>
</div>

<ng-template #tacticTemplate>
  <div class="info__row" *ngIf="description$ | async as desc">
    <div class="info__group">
      <div i18n>Tactic:</div>
      <div>{{ desc.name }} - {{ desc.description }}</div>
    </div>
  </div>
</ng-template>

<ng-template #relatedTacticsTemplate>
  <div class="info__row" *ngIf="relatedTactics$ | async as relatedTactics">
    <div class="info__group">
      <div i18n>Related Tactics: </div>
      <div class="info__description">{{ relatedTactics }}</div>
    </div>
  </div>
</ng-template>
