<div
  [ngClass]="{
    'column-title': true,
    'column-title-start': firstColumn,
    'column-title-end': lastColumn
  }"
>
  <h3 i18n-title title="Tactic: {{ tactic.name }}, Techniques count: {{ totalCount }}">{{ tactic.name }} ({{ totalCount }})</h3>
</div>
<ul class="column-tree">
  <li *ngFor="let technique of tactic.techniques">
    <mitre-tactic-column-node
      [technique]="technique"
      [currentTactic]="tactic.shortName"
      [rightSidedNode]="rightSidedColumn"
      [lastNode]="lastColumn">
    </mitre-tactic-column-node>
  </li>
</ul>
