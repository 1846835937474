import { Component, TemplateRef, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'mitre-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalContentComponent implements OnInit {

  title: string;
  titleTemplateRef: TemplateRef<void>;
  contentTemplateRef: TemplateRef<void>;

  constructor(public readonly modalRef: BsModalRef) {}

  ngOnInit(): void {
  }

  hide() {
    this.modalRef.hide();
  }
}
