import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { EModalName, ShowModal } from '../../store/actions/modal.action';

interface NavItem {
  title: string;
  routerLink: string;
}

@Component({
  selector: 'mitre-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  items: NavItem[] = [];

  isSentinel = false;

  currentRoute: string;

  constructor(
    private readonly _store: Store<{}>,
    private readonly _router: Router
  ) {}

  ngOnInit(): void {
    // subscribe to router navigation end event to get current route
    this._router.events
      .pipe(filter((event: NavigationEnd) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.currentRoute = event.url;
        this.isSentinel = this.currentRoute.indexOf('sentinel') > -1;

        if (this.isSentinel) {
          this.items = [
            {
              title: 'Sentinel',
              routerLink: '/sentinel',
            },
          ];
        } else {
          this.items = [
            {
              title: 'Layered Analytics (All)',
              routerLink: '/layered-analytics',
            },
            {
              title: 'Real-Time',
              routerLink: '/real-time',
            },
            {
              title: 'Machine Learning',
              routerLink: '/machine-learning',
            },
            {
              title: 'Search and Hunt',
              routerLink: '/search-and-hunt',
            },
          ];
        }
      });
  }

  showLandingModal(event: MouseEvent): void {
    event.preventDefault();

    this._store.dispatch(
      new ShowModal({
        name: EModalName.Landing,
      })
    );
  }
}
