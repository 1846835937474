import { Component, HostListener, Input } from '@angular/core';
import { ISolutionColorScheme, ITechnique } from '../../models/solution';
import { SolutionGridService } from '../../services/solution-grid.service';
import { ETacticColumnNodeState } from './tactic-column-node-state';
import { ETacticColumnNodeStatus } from './tactic-column-node-status';

@Component({
  selector: 'mitre-tactic-column-node-button',
  templateUrl: './tactic-column-node-button.component.html',
  styleUrls: ['./tactic-column-node.component.scss'],
})
export class TacticColumnNodeButtonComponent {
  /**
   * Reference to the state enum for accessing in the view
   */
  ETacticColumnNodeState = ETacticColumnNodeState;

  /**
   * Reference to status enum for accessing in the view
   */
  ETacticColumnNodeStatus = ETacticColumnNodeStatus;

  /**
   * The current technique data
   */
  @Input() technique: ITechnique;

  /**
   * Pass in the currentTactic short name
   */
  @Input() currentTactic: string;

  /**
   * Determine whether in covered or uncovered mode
  */
  @Input() status: ETacticColumnNodeStatus;

  /**
   * Determine the visual state of the node
   */
  @Input() state: ETacticColumnNodeState;

  /**
   * Determines if ui elements are aligned for the right half of the screen
   */
  @Input() rightSidedNode: boolean;

  /**
   * Determines if this is the last displayed column
   */
  @Input() lastNode: boolean;

  /**
   * Flag to determine if this technique has subtechniques
   */
  @Input() hasSubTechniques: boolean;

  /**
  * The current solution color scheme
  */
  @Input() colorScheme: ISolutionColorScheme;

  /**
   * Show sub-techniques in the UI (used for toggle button)
   */
  @Input() showSubTechniques;

  /**
   * Listen for mouse enter event
   */
  @HostListener('mouseenter')
  onMouseEnter() {
    this.mouseEnter();
  }

  /**
   * Listen for mouse leave event
   */
  @HostListener('mouseleave')
  onMouseLeave() {
    this.mouseLeave();
  }

  constructor(private _solutionGridService: SolutionGridService) {}

  mouseEnter(): void {
    this.state = ETacticColumnNodeState.Highlighted;

    // change associated to highlighted state
    if (this.technique.tactics.length > 1) {
      this._solutionGridService.changeAssociatedNodesEvent$.next({
        code: this.technique.code,
        tactics: this.technique.tactics.filter(t => t !== this.currentTactic),
        state: ETacticColumnNodeState.Highlighted
      });
    }
  }

  mouseLeave(): void {
    this.state = ETacticColumnNodeState.Active;

    // change associated to active state
    if (this.technique.tactics.length > 1) {
      this._solutionGridService.changeAssociatedNodesEvent$.next({
        code: this.technique.code,
        tactics: this.technique.tactics.filter(t => t !== this.currentTactic),
        state: ETacticColumnNodeState.Active
      });
    };
  }
}
