<ux-accordion [collapseOthers]="true">
  <ux-accordion-panel
    class="accordion-chevron"
    heading="Legend"
    [expanded]="true"
  >
    <ng-container *ngIf="isLayeredAnalytics$ | async; else singleTemplate">
      <ul class="legend__items">
        <li class="legend__item">
          <div class="legend__indicator indicator--covered-default">
            <span [style.backgroundColor]="colors[0]"></span>
          </div>
          <span i18n>Technique covered in Real-Time</span>
        </li>

        <li class="legend__item">
          <div class="legend__indicator indicator--covered-default">
            <span [style.backgroundColor]="colors[1]"></span>
          </div>
          <span i18n>Technique covered in Machine Learning</span>
        </li>

        <li class="legend__item">
          <div class="legend__indicator indicator--covered-default">
            <span [style.backgroundColor]="colors[2]"></span>
          </div>
          <span i18n>Technique covered in Search and Hunt </span>
        </li>

        <li class="legend__item">
          <span class="legend__indicator indicator--not-covered"></span>
          <span i18n>Not covered in content</span>
        </li>
      </ul>
    </ng-container>

    <ng-template #singleTemplate>
      <ul class="legend__items">
        <li class="legend__item">
          <div class="legend__indicator indicator--covered-default">
            <span [style.backgroundColor]="colors[0]"></span>
          </div>
          <span i18n *ngIf="isSentinel">Technique covered out-of-the-box</span>
          <span i18n *ngIf="!isSentinel"
            >Technique covered in default content</span
          >
        </li>

        <li class="legend__item">
          <div
            class="legend__indicator indicator--covered-other"
            [style.background-color]="colors[1]"
          >
            <span [style.border-top-color]="colors[0]"></span>
          </div>
          <span i18n>Technique covered in other content</span>
        </li>

        <li class="legend__item">
          <span class="legend__indicator indicator--not-covered"></span>
          <span i18n>Not covered in content</span>
        </li>
      </ul>
    </ng-template>
  </ux-accordion-panel>
</ux-accordion>
