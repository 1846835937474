import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TruncateTextService {
  // flag to check for resize in progress
  isResizing$ = new BehaviorSubject<boolean>(false);

  // handler directives listen to (triggered by window resize)
  resizeHandler$ = new Subject<void>();
}
