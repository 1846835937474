/**
 * Valid solution references used for checking routing - must be updated if new solutions are added to the data.
 */
export const SOLUTION_REFERENCES: string[] = [
  'layered-analytics',
  'real-time',
  'machine-learning',
  'search-and-hunt',
  'sentinel',
];

/**
 * This is used to set the default solution to load
 */
export const DEFAULT_SOLUTION_REFERENCE: string = SOLUTION_REFERENCES[0];

/**
* Whenever we click on the 'Cookie Policy' button, a window with this URL will open
*/
export const COOKIE_POLICY_URL: string = 'https://www.opentext.com/about/cookie-policy';

/**
 * Whenever we click on the 'Privacy Policy' button, a window with this URL will open
 */
export const PRIVACY_POLICY_URL: string = 'https://www.opentext.com/about/privacy';