import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ModalComponent } from './shared/components/modal/modal.component';
import { ModalService } from './shared/services/modal.service';
import { SolutionService } from './shared/services/solution.service';
import { IAppState } from './shared/store';
import { EModalName, ShowModal } from './shared/store/actions/modal.action';
import {
  ICurrent,
  selectCurrentModal,
} from './shared/store/selectors/modal.selector';
import { selectSelectedSolutionReference } from './shared/store/selectors/solution.selector';

@Component({
  selector: 'mitre-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  @ViewChild('landingModal') landingModal: ModalComponent;
  @ViewChild('exportModal') exportModal: ModalComponent;
  @ViewChild('techniqueModal') techniqueModal: ModalComponent;

  currentModal$: Observable<ICurrent | null>;

  isSentinel = false;

  constructor(
    private readonly _store: Store<IAppState>,
    private readonly _solutionService: SolutionService,
    public modalService: ModalService,
    @Inject(Window) private readonly _window: Window,
    private readonly _router: Router
  ) {
    this.currentModal$ = this._store.pipe(select(selectCurrentModal));
  }

  ngOnInit(): void {
    const hideLandingModal = this._window.localStorage.getItem(
      'cyberres-mitre-hide-landing-modal'
    );

    if (hideLandingModal !== '1') {
      this._store.dispatch(
        new ShowModal({
          name: EModalName.Landing,
        })
      );

      this._window.localStorage.setItem(
        'cyberres-mitre-hide-landing-modal',
        '1'
      );
    }

    this._store
      .select(selectSelectedSolutionReference)
      .pipe(
        filter((ref) => Boolean(ref)),
        takeUntil(this._destroy$)
      )
      .subscribe((ref) => (this.isSentinel = ref === 'sentinel'));
  }

  ngAfterViewInit(): void {
    this.currentModal$.subscribe((current: ICurrent) => {
      switch (current?.name) {
        case EModalName.Landing:
          this.landingModal.show({ name: current.name });
          break;

        case EModalName.Export:
          this._solutionService.downloadExport();
          this.exportModal.show({ name: current.name });
          break;

        case EModalName.Technique:
          this.techniqueModal.show({
            name: current.name,
            technique: current.technique,
            currentTactic: current.currentTactic,
          });
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  hideLandingModal(event: MouseEvent): void {
    event.preventDefault();
    this.landingModal.hide();
  }

  downloadExportedSolution(event: MouseEvent): void {
    event.preventDefault();
    this._solutionService.downloadExport();
  }
}
