import { AfterViewInit, Component, Input, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ISolution } from '../../models/solution';
import { SolutionGridService } from '../../services/solution-grid.service';
import { ETacticColumnNodeState } from '../tactic-column-node';
import { TacticColumnComponent } from '../tactic-column/tactic-column.component';

@Component({
  selector: 'mitre-solution-grid',
  templateUrl: './solution-grid.component.html',
  styleUrls: ['./solution-grid.component.scss'],
})
export class SolutionGridComponent implements AfterViewInit, OnDestroy {
  /**
   * Subject for garbage collection
   */
  private _destroy$ = new Subject<void>();

  /**
   * Solution data to populate the grid
   */
  @Input() solution: ISolution;

  /**
   * Reference to all column components
   */
  @ViewChildren(TacticColumnComponent)
  columns: QueryList<TacticColumnComponent>;

  /**
   * Set top margin to push grid below header and description
   */
  @Input()
  gridMarginTop: number;

  @Input()
  gridWidth: number;

  constructor(private _solutionGridService: SolutionGridService) {}

  ngAfterViewInit(): void {
    // highlight nodes with the same code across multiple columns
    this._solutionGridService.changeAssociatedNodesEvent$
      .pipe(takeUntil(this._destroy$))
      .subscribe(event => {
        if (event) {
          this.changeNodeState(event.code, event.tactics, event.state);
        }
      });

    // toggle show sub-techniques
    this._solutionGridService.showSubTechniques$.pipe(takeUntil(this._destroy$))
      .subscribe(showSubTechnqiues => this.toggleSubTechniques(showSubTechnqiues));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
   * Checks all columns for node with the same code as the passed value and updates the state
   * @param code Technique code to identify
   * @param state Node state to change to
   */
  changeNodeState(code: string, tactics: string[], state: ETacticColumnNodeState) {
    const relevantColumns = this.columns.filter(c => tactics.indexOf(c.tactic.shortName) > -1);

    for (const column of relevantColumns) {
      column.changeNodeState(code, state);
    }
  }

  /**
   * Cycles through the columns updating the show flag for sub-techniques
   * @param show New state of the show flag
   */
  toggleSubTechniques(show: boolean): void {
    for (const column of this.columns.toArray()) {
      column.toggleSubTechniques(show);
    }
  }
}
