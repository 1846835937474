import { RouterReducerState } from '@ngrx/router-store';
import { ISolutionState, initialSolutionState } from './solution.state';
import { IModalState, initialModalState } from './modal.state';
import { IDescriptionState } from './description.state';

export interface IAppState {
  router?: RouterReducerState;
  solutions: ISolutionState;
  modals?: IModalState;
  tacticDescriptions?: IDescriptionState;
}

export const initialAppState: IAppState = {
  solutions: initialSolutionState,
  modals: initialModalState,
};

export function getInitialAppState(): IAppState {
  return initialAppState;
}
