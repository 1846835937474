<ux-accordion [collapseOthers]="true">
    <ux-accordion-panel
      class="accordion-chevron"
      heading="Cookie and Privacy Management"
      [expanded]="false"
    >
        <div class="cookie-button-panel">
            <button (click)="goToPrivacyPolicyPage()">Privacy Policy</button>
            <button (click)="goToCookiePolicyPage()">Cookie Policy</button>
            <button (click)="manageCookiePreferences()">Cookie Preferences</button>
        </div>
    </ux-accordion-panel>
</ux-accordion>
