import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, Renderer2, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TruncateTextService } from '../services/truncate-text.service';

@Directive({
  selector: '[mitreTruncateText]',
})
export class TruncateTextDirective implements OnChanges, OnDestroy, AfterViewInit {

  private _container: HTMLDivElement;
  private _availableSpace: number;
  private _factor = 9;
  private _margin = 10;
  private _displayedText: string;
  private _resizeTimeout = null;
  private _destroy$ = new Subject<void>();

  @Input()
  truncateText = '';

  @Input()
  truncateTextMaxLines = 2;

  constructor(
    private _element: ElementRef,
    private _renderer: Renderer2,
    private _truncateTextService: TruncateTextService
  ) {
    this._container = this._renderer.createElement('div');
    this._container.classList.add('truncate-text__container');
    this._container.style.display = 'block';
    this._container.style.margin = '0 auto';
    this._container.style.wordBreak = 'break-word';
    this._renderer.appendChild(this._element.nativeElement, this._container);

    // track resize handler for resize change
    this._truncateTextService.resizeHandler$.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this._availableSpace = this.calculateAvailableSpace();
      this.renderText();
    });
  }

  @HostListener('window:resize')
  onResize(): void {
    if (!this._truncateTextService.isResizing$.value === true) {
      this._truncateTextService.isResizing$.next(true);

      if (this._resizeTimeout) {
        clearTimeout(this._resizeTimeout);
      }

      this._resizeTimeout = setTimeout(() => {
        this._truncateTextService.isResizing$.next(false);
        this._truncateTextService.resizeHandler$.next();
      }, 500);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.truncateText.currentValue !== this.truncateText || changes.truncateText.firstChange) {
      this._displayedText = this.truncateText.replace(/\//g, ' / ');
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngAfterViewInit(): void {
    this._availableSpace = this.calculateAvailableSpace();
    this.renderText();
  }

  renderText(): void {
    this._container.innerHTML = this.validDisplayText();
  }

  validDisplayText(): string {
    const maxCharsPerLine = Math.floor(this._availableSpace / this._factor);

    // if too small for one line just return the full string
    if (this._displayedText.length < maxCharsPerLine) {
      return this._displayedText;
    }

    const totalChars = maxCharsPerLine * this.truncateTextMaxLines;

    const temp = this._displayedText.split(/\s/g);

    let currentLine = 0;
    let stringsProcessed = 0;
    let currentLineCharsCount = 0;
    const lines: string[][] = [];

    for (const str of temp) {
      // move to new line when character count is exceeded
      if ((currentLineCharsCount + str.length + (lines[currentLine]?.length || 0)) > maxCharsPerLine && currentLineCharsCount > 0) {
        // if max lines has been hit
        if (currentLine === this.truncateTextMaxLines - 1) {
          const charsRemaining = maxCharsPerLine - this.charsInLine(lines[currentLine]) - 1;

          // if some character spaces remaining add part of the current word
          if (charsRemaining > 0) {
            lines[currentLine].push(str.substring(0, charsRemaining));
          }

          continue;
        }

        currentLine++;
        currentLineCharsCount = lines[currentLine] ? this.charsInLine(lines[currentLine]) : 0;
      }

      // create array for new line when not existing
      if (!lines[currentLine]) {
        lines[currentLine] = [];
      }

      // split the word if it's too long for the line
      if (str.length > maxCharsPerLine) {
        const charsToAdd = maxCharsPerLine - this.charsInLine(lines[currentLine]);
        const partialStr: string = str.substring(0, charsToAdd);
        const partialStr2: string = str.substring(charsToAdd, str.length);
        lines[currentLine].push(partialStr);
        currentLineCharsCount += partialStr.length;

        // add the second part to a new line if the max lines count allows it
        if (currentLine !== this.truncateTextMaxLines - 1) {
          lines[currentLine + 1] = [ partialStr2 ];
        };
      } else {
        // just add the full word
        lines[currentLine].push(str);
        currentLineCharsCount += str.length;
      }

      stringsProcessed++;
    }

    // render the final text
    return this.renderLine(lines, maxCharsPerLine, stringsProcessed < temp.length || lines[currentLine].indexOf(temp[temp.length - 1]) === -1);
  }

  renderLine(lines: string[][], maxCharsPerLine: number, showEllipsis: boolean): string {
    let lineText = lines.map(l => l.join(' ')).join('<br/>');

    if (showEllipsis) {
      const unusedFinalLineChars = maxCharsPerLine - this.charsInLine(lines[lines.length - 1]);
      const diff = 3 - unusedFinalLineChars;
      lineText= lineText.substring(0, lineText.length - diff - 1);

      if (lineText.lastIndexOf(' ') === lineText.length - 1) {
        lineText = lineText.substring(0, lineText.length - 1);
      }

      return `${lineText}&hellip;`;
    } else {
      return lineText;
    }
  }

  charsInLine(line: string[]): number {
    let count = 0;

    for (const str of line) {
      count += str.length;
    }

    return count;
  }

  calculateAvailableSpace(): number {
    const parentWidth = this._element.nativeElement.parentElement.offsetWidth;
    this._margin = parentWidth > 120 ? 10 : 6;
    return parentWidth - (this._margin * 2);
  }
}
