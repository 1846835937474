import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { IAppData } from '../models/data/app-data';
import { GetAppData } from '../store/actions/solution.action';
import { IAppState } from '../store/index';
import { selectAppDataLoaded } from '../store/selectors/solution.selector';

@Injectable()
export class SolutionResolver implements Resolve<any> {
  appData: IAppData;

  constructor(private _store: Store<IAppState>) {}

  waitForAppDataToLoad(): Observable<boolean> {
    return this._store.pipe(
      select(selectAppDataLoaded),
      filter(loaded => loaded),
      take(1)
    );
  }

  resolve() {
    if (this.appData) {
      return true;
    }

    // fire off call to load app data
    this._store.dispatch(new GetAppData());

    // watch for data being loaded
    return this.waitForAppDataToLoad();
  }
}
