<mitre-header></mitre-header>
<div id="router-wrapper">
  <router-outlet></router-outlet>
</div>

<mitre-modal i18n-title size="modal-xlg" #landingModal>
  <div class="custom-modal-header">
    <h4 *ngIf="isSentinel" id="modal-title" class="modal-title">
      MITRE ATT&CK<sup>&reg;</sup> Navigator for OpenText Sentinel
    </h4>
    <h4 *ngIf="!isSentinel" id="modal-title" class="modal-title">
      MITRE ATT&CK<sup>&reg;</sup> Navigator for OpenText ArcSight Products
    </h4>
  </div>

  <p class="modal-text" i18n>
    Welcome to the MITRE ATT&CK<sup>&reg;</sup> Navigator for OpenText SecOps
    (Security Operations) products.
  </p>
  <p *ngIf="isSentinel" class="modal-text" i18n>
    Give your Security Operations Center (SOC) a fighting chance to find threats
    before they turn into a breach. Use this navigator to view which MITRE
    ATT&CK® Techniques are covered by Sentinel.
  </p>
  <p *ngIf="!isSentinel" class="modal-text" i18n>
    Give your Security Operations Center (SOC) a fighting chance to find threats
    before they turn into a breach. Use this navigator to view which MITRE
    ATT&CK® Techniques are covered by OpenText ArcSight Solutions.
  </p>
  <a href="#" class="btn btn-primary" (click)="hideLandingModal($event)"
    >Get Started</a
  >
</mitre-modal>

<mitre-modal title="Export" i18n-title size="modal-xlg" #exportModal>
  <p class="modal-text" i18n>
    Download has started. If nothing happened, please
    <span
      class="link-external span-as-link"
      (click)="downloadExportedSolution($event)"
      >click here</span
    >.
  </p>
  <p class="modal-text" i18n>
    You can use this exported file to upload your current view into
    <a
      href="https://mitre-attack.github.io/attack-navigator/enterprise/"
      target="mitre-attack-navigator"
      class="link-external"
      >MITRE ATT&CK® Navigator</a
    >, in order to compare coverage levels and SOC maturity levels.
  </p>
</mitre-modal>

<mitre-modal
  size="modal-xlg"
  (modalDismiss)="modalService.reset()"
  #techniqueModal
>
  <div class="custom-modal-header">
    <ol class="breadcrumb">
      <li
        (click)="modalService.reset()"
        [ngClass]="{ faded: modalService.selectedSubtechnique$ | async }"
      >
        <h4 class="technique-title modal-title">
          {{ (currentModal$ | async)?.technique?.name }}
        </h4>
      </li>
      <li *ngIf="modalService.selectedSubtechnique$ | async as subtechnique">
        <h4 class="subtechnique-title modal-title">{{ subtechnique.name }}</h4>
      </li>
    </ol>
  </div>
  <mitre-technique-modal-content
    [technique]="(currentModal$ | async)?.technique"
    [currentTactic]="(currentModal$ | async)?.currentTactic"
  ></mitre-technique-modal-content>
</mitre-modal>
