import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { select, Store } from '@ngrx/store';
import { Observable, of, Subject } from "rxjs";
import { switchMap, takeUntil } from 'rxjs/operators';
import { DEFAULT_SOLUTION_REFERENCE } from '../../constants/solution.constants';
import { ISolutionColorScheme, ITechnique } from '../../models/solution';
import { TechniqueService } from '../../services/technique.service';
import { selectSelectedSolution, selectSolutionMainColorByReferences } from '../../store/selectors/solution.selector';
import { IAppState } from '../../store/state';
import { ETacticColumnNodeState, ETacticColumnNodeStatus } from '../tactic-column-node';

@Component({
  selector: 'mitre-tactic-column-sub-node',
  templateUrl: './tactic-column-sub-node.component.html',
  styleUrls: [ './tactic-column-sub-node.component.scss' ]
})
export class TacticColumnSubNodeComponent implements OnChanges, OnDestroy {
  /**
   * Subject for garbage collection
   */
  private _destroy$ = new Subject<void>();

  /**
   * Reference to the state enum for accessing in the view
   */
  ETacticColumnNodeState = ETacticColumnNodeState;

  /**
   * Reference to status enum for accessing in the view
   */
  ETacticColumnNodeStatus = ETacticColumnNodeStatus;

  /**
   * The technique displayed in this component
   */
  @Input() technique: ITechnique;

  /**
   * parent of this technique (passed for modals)
   */
  @Input() parentTechnique: ITechnique;

  /**
   * Used to customise the ui for first node
   */
  @Input() firstNode = false;

  /**
   * Determines if ui elements are aligned for the right half of the screen
   */
  @Input() rightSidedNode: boolean;

  /**
   * The current status of the sub-technique
   */
  status: ETacticColumnNodeStatus;

  /**
   * The current view state of the sub-technique
   */
  state: ETacticColumnNodeState;

  /**
   * The current solution color scheme
   */
  colorScheme: ISolutionColorScheme

  /**
   * Get the bar colors based on the solution
   */
  barColors$: Observable<string[]> = this._store.pipe(
    select(selectSelectedSolution),
    switchMap(solution => {
      if (solution.reference === DEFAULT_SOLUTION_REFERENCE) {
        const references = this._techniqueService.getProductSolutions(this.technique);
        return this._store.pipe(select(selectSolutionMainColorByReferences, { references }));
      }

      return of([solution.colors.main]);
    }),
    takeUntil(this._destroy$)
  );

  /**
   * Dispatch event to open sub-technique modal
   */
  @Output() showSubtechnique = new EventEmitter<ITechnique>();

  /**
   * Listen for mouse enter event
   */
  @HostListener('mouseenter', ['$event'])
  onMouseEnter(event: MouseEvent) {
    event.preventDefault();
    this.state = ETacticColumnNodeState.Highlighted;
  }

  /**
   * Listen for mouse leave event
   */
  @HostListener('mouseleave', ['$event'])
  onMouseLeave(event: MouseEvent) {
    event.preventDefault();
    this.state = ETacticColumnNodeState.Active;
  }

  constructor(
    private _store: Store<IAppState>,
    private _techniqueService: TechniqueService
  ) {
    this.status = this.determineStatus();
    this.state = ETacticColumnNodeState.Active;

    this._techniqueService.solutionColorScheme$
      .pipe(takeUntil(this._destroy$))
      .subscribe(colorScheme => this.colorScheme = colorScheme);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.technique && changes.technique.currentValue !== changes.technique.previousValue) {
      // set the status based on the technique having products
      this.status = this.determineStatus();

      // set state to active
      this.state = ETacticColumnNodeState.Active;
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private determineStatus(): ETacticColumnNodeStatus {
    if (this.technique && this.technique.isOtherContent) {
      return ETacticColumnNodeStatus.OtherContent;
    }

    if (this.technique && this.technique?.products?.length > 0) {
      return ETacticColumnNodeStatus.Covered;
    }

    return ETacticColumnNodeStatus.NotCovered;
  }
}
